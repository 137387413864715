import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
};

export function CartIcon({ width = 64, height = 64 }: Props): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.315 48.0735C21.315 50.3533 23.1615 52.1998 25.4412 52.1998C27.721 52.1998 29.5675 50.3533 29.5675 48.0735C29.5675 45.8041 27.721 43.9473 25.4412 43.9473C23.1615 43.9473 21.315 45.8041 21.315 48.0735Z"
        stroke="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.8838 48.0735C39.8838 50.3533 41.7303 52.1998 44.01 52.1998C46.2898 52.1998 48.1363 50.3533 48.1363 48.0735C48.1363 45.8041 46.2898 43.9473 44.01 43.9473C41.7303 43.9473 39.8838 45.8041 39.8838 48.0735Z"
        stroke="black"
      />
      <path
        d="M15.1263 17.1265H11M53.2943 22.2843L49.168 39.821L53.2943 22.2843ZM49.168 39.821H20.2841H49.168ZM15.1263 17.1265L20.2841 39.821L15.1263 17.1265Z"
        stroke="black"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.2949 22.2841H44.0108H53.2949ZM44.0108 22.2841C44.0108 27.4213 39.8536 31.5682 34.7267 31.5682C29.5998 31.5682 25.4426 27.4213 25.4426 22.2841C25.4426 17.1572 29.5998 13 34.7267 13C39.8536 13 44.0108 17.1572 44.0108 22.2841ZM25.4426 22.2841H16.1584H25.4426Z"
        stroke="black"
      />
      <path
        d="M34.7259 17.1265V27.4421M34.7259 27.4421L30.5996 23.3159M34.7259 27.4421L38.8522 23.3159"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
