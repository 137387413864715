import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
};

export function WalletIcon({ width = 45, height = 42 }: Props): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 45 42"
      fill="none"
    >
      <path
        d="M36.9452 24.448V21.0983C36.9452 18.3264 34.7009 16.0738 31.9206 16.0738H6.79807C5.87691 16.0738 5.12323 15.3285 5.12323 14.3989V13.5615"
        stroke="black"
        strokeLinejoin="round"
      />
      <path
        d="M36.9452 32.8221V36.1718C36.9452 38.952 34.7009 41.1963 31.9206 41.1963H6.79807C5.87691 41.1963 5.12323 40.451 5.12323 39.5215V16.0737"
        stroke="black"
        strokeLinejoin="round"
      />
      <path
        d="M18.5224 11.8862H31.9211C34.7013 11.8862 36.9456 14.1389 36.9456 16.9107"
        stroke="black"
        strokeLinejoin="round"
      />
      <path
        d="M5.12323 16.9107V13.5611C5.12323 12.6399 5.87691 11.8862 6.79807 11.8862H10.1477"
        stroke="black"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.7343 32.8215H37.7833C38.7129 32.8215 39.4582 32.0762 39.4582 31.1466V26.1221C39.4582 25.2009 38.7129 24.4473 37.7833 24.4473H27.7343C26.8123 24.4473 26.0594 25.2009 26.0594 26.1221V31.1466C26.0594 32.0762 26.8123 32.8215 27.7343 32.8215Z"
        stroke="black"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.1077 28.6343C36.1077 29.5638 35.3624 30.3091 34.4328 30.3091C33.5117 30.3091 32.758 29.5638 32.758 28.6343C32.758 27.7131 33.5117 26.9595 34.4328 26.9595C35.3624 26.9595 36.1077 27.7131 36.1077 28.6343Z"
        stroke="black"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.9452 21.0977V16.9106V21.0977Z"
        stroke="black"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5227 10.212C18.5227 12.5316 16.6552 14.3991 14.3356 14.3991C12.0235 14.3991 10.1485 12.5316 10.1485 10.212C10.1485 7.90072 12.0235 6.0249 14.3356 6.0249C16.6552 6.0249 18.5227 7.90072 18.5227 10.212Z"
        stroke="black"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4084 5.1871C29.4084 7.50675 27.5409 9.37419 25.2213 9.37419C22.91 9.37419 21.0342 7.50675 21.0342 5.1871C21.0342 2.87582 22.91 1 25.2213 1C27.5409 1 29.4084 2.87582 29.4084 5.1871Z"
        stroke="black"
        strokeLinejoin="round"
      />
    </svg>
  );
}
