import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
};

export function WatchIcon({ width = 48, height = 48 }: Props): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8271_14204)">
        <path
          d="M0.998047 24.002C0.998047 27.0224 1.59296 30.0132 2.74882 32.8037C3.90468 35.5942 5.59884 38.1297 7.73459 40.2654C9.87034 42.4012 12.4058 44.0953 15.1963 45.2512C17.9868 46.407 20.9776 47.002 23.998 47.002C27.0185 47.002 30.0093 46.407 32.7998 45.2512C35.5903 44.0953 38.1258 42.4012 40.2615 40.2654C42.3972 38.1297 44.0914 35.5942 45.2473 32.8037C46.4031 30.0132 46.998 27.0224 46.998 24.002C46.998 20.9816 46.4031 17.9907 45.2473 15.2002C44.0914 12.4097 42.3972 9.87425 40.2615 7.7385C38.1258 5.60275 35.5903 3.90858 32.7998 2.75272C30.0093 1.59687 27.0185 1.00195 23.998 1.00195C20.9776 1.00195 17.9868 1.59687 15.1963 2.75272C12.4058 3.90858 9.87034 5.60275 7.73459 7.7385C5.59884 9.87425 3.90468 12.4097 2.74882 15.2002C1.59296 17.9907 0.998047 20.9816 0.998047 24.002Z"
          stroke="black"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24 13.002V24.002L36 35.002"
          stroke="black"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8271_14204">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
