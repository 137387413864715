import React, { Suspense } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { ThemeProvider, CssBaseline } from '@mui/material';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './redux/store';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { themeMaterial } from './theme';
import { Amplify, Auth } from 'aws-amplify';
import amplifyConfig from './aws-exports';
import ToastMessage, {
  ToastType,
} from './components/Common/ToastMessage/ToastMessage';
import commonConstants from './constants/common.constant';
import { logout } from './redux/slices/authSlice';
import loadable from '@loadable/component';

const UnderMaintenance = loadable(
  () => import('./components/UnderMaintenance/UnderMaintenance'),
);
const LayoutWithNav = loadable(
  () => import('./components/Layout/LayoutWithNav'),
);
const ProtectedRoute = loadable(
  () => import('./components/Auth/ProtectedRoute'),
);
const AuthRoutes = loadable(() => import('./pages/Auth/AuthRoutes'));
const PageNotFound = loadable(() => import('./pages/PageNotFound'));
const Home = loadable(() => import('./pages/Home'));
const AssignCategory = loadable(() => import('./pages/AssignCategory'));
const ManagingVendors = loadable(() => import('./pages/ManagingVendors'));
const VendorDetails = loadable(() => import('./pages/VendorDetails'));
const ReferrerDetails = loadable(() => import('./pages/ReferrerDetails'));
const ReferralDetails = loadable(() => import('./pages/ReferralDetails'));
const Stores = loadable(() => import('./pages/Stores/Stores'));
const VendorsDashboard = loadable(() =>
  import('./components/VendorsDashboard').then(
    (module) => module.VendorsDashboard,
  ),
);
const ReferralsDashboard = loadable(() =>
  import('./components/ReferralsDashboard').then(
    (module) => module.ReferralsDashboard,
  ),
);
const ReferrersDashboard = loadable(() =>
  import('./components/ReferrersDashboard').then(
    (module) => module.ReferrersDashboard,
  ),
);

Amplify.configure(amplifyConfig);

const checkExpiredAndLogout = async (error: any) => {
  // Check Expired Time here to logout;
  try {
    await Auth.currentSession();
  } catch (err: any) {
    if (store.getState()?.auth?.user) {
      queryClient.invalidateQueries();
      persistor.purge();
      store.dispatch(logout());
      toast(
        <ToastMessage
          text={commonConstants.EXPIRED_SESSION_MESSAGE}
          type={ToastType.ERROR.type}
        />,
      );
    }
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        // Fallback Error Catch If we don't define onError when using useQuery
        toast(
          <ToastMessage
            text={error?.message ?? commonConstants.SOMETHING_WENT_WRONG}
            type={ToastType.ERROR.type}
          />,
        );
      },
    },
    mutations: {
      retry: false,
      onError: (error: any) => {
        // Fallback Error Catch If we don't define onError when using useMutate
        toast(
          <ToastMessage
            text={error?.message ?? commonConstants.SOMETHING_WENT_WRONG}
            type={ToastType.ERROR.type}
          />,
        );
      },
    },
  },
  queryCache: new QueryCache({
    onError: checkExpiredAndLogout,
  }),
  mutationCache: new MutationCache({
    onError: checkExpiredAndLogout,
  }),
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <HelmetProvider>
        <Helmet>
          <title>iRefer Admin App</title>
        </Helmet>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={themeMaterial}>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <BrowserRouter>
                <Suspense fallback={<></>}>
                  <Routes>
                    <Route path="/auth/*" element={<AuthRoutes />} />
                    <Route
                      path="/"
                      element={
                        <ProtectedRoute>
                          <Outlet />
                        </ProtectedRoute>
                      }
                    >
                      <Route element={<LayoutWithNav />}>
                        <Route index element={<Home />} />
                        <Route
                          path="managing-vendors"
                          element={<ManagingVendors />}
                        />
                        <Route path="stores" element={<Stores />} />
                        <Route
                          path="assign-category"
                          element={<AssignCategory />}
                        />
                        <Route path="vendors">
                          <Route index element={<VendorsDashboard />} />
                          <Route path=":vendorId">
                            <Route index element={<VendorDetails />} />
                          </Route>
                        </Route>
                        <Route path="referrers">
                          <Route index element={<ReferrersDashboard />} />
                          <Route path=":referrerId">
                            <Route index element={<ReferrerDetails />} />
                            <Route
                              path=":vendorId"
                              element={<ReferrerDetails />}
                            />
                          </Route>
                        </Route>
                        <Route path="referrals">
                          <Route index element={<ReferralsDashboard />} />
                          <Route path=":referralId">
                            <Route index element={<ReferralDetails />} />
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                    <Route
                      path="under-maintenance"
                      element={<UnderMaintenance />}
                    />
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </Suspense>
              </BrowserRouter>
              <ToastContainer
                className="toaster-container"
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                closeButton={false}
              />
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </HelmetProvider>
    </QueryClientProvider>
  );
}

export default App;
