import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from '../../constants/common.constant';

export const PROPERTY_KEY = {
  PAGE: 'page',
  KEYWORD: 'keyword',
  DIRECTION: 'direction',
  STATUS: 'status',
};

const initialState = {
  page: commonConstants.MANAGING_VENDOR_DASHBOARD_DEFAULT_QUERY_PARAMS.page,
  limit: commonConstants.MANAGING_VENDOR_DASHBOARD_DEFAULT_QUERY_PARAMS.limit,
  keyword:
    commonConstants.MANAGING_VENDOR_DASHBOARD_DEFAULT_QUERY_PARAMS.keyword,
  direction:
    commonConstants.MANAGING_VENDOR_DASHBOARD_DEFAULT_QUERY_PARAMS.direction,
  status: commonConstants.MANAGING_VENDOR_DASHBOARD_DEFAULT_QUERY_PARAMS.status,
};

type PayloadProperty = {
  type: string;
  value: string | number | Date[] | any[];
};

type PayloadProperties = PayloadProperty[];

const MainDashboardSlice = createSlice({
  name: 'mainDashboard',
  initialState,
  reducers: {
    setProperty: (state, action: PayloadAction<PayloadProperty>) => {
      // @ts-ignore
      state[action.payload.type] = action.payload.value;
    },
    setProperties: (state, action: PayloadAction<PayloadProperties>) => {
      if (action.payload.length > 0) {
        action.payload.forEach((item: PayloadProperty) => {
          if (state.hasOwnProperty(item.type)) {
            // @ts-ignore
            state[item.type] = item.value;
          }
        });
      }
    },
  },
});

export const { setProperty, setProperties } = MainDashboardSlice.actions;

export default MainDashboardSlice.reducer;
