import { createSlice } from '@reduxjs/toolkit';

export type User = {
  id: string;
  username: string;
  email: string;
  is_2fa: boolean;
  phone: string;
};

type AuthState = {
  isAuthenticated: boolean;
  user: User | null;
  is_2fa: boolean;
};

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  is_2fa: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.is_2fa = false;
    },
    setVerify2FA: (state, action) => {
      state.is_2fa = action.payload;
    },
  },
});

export const { login, logout, setVerify2FA } = authSlice.actions;

export default authSlice.reducer;
