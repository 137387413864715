import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
};

export function TargetIcon({ width = 64, height = 64 }: Props): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.5809 15.775V10V15.775Z"
        stroke="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.5809 56.1998V50.4248V56.1998Z"
        stroke="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.4253 32.6187H55.2003H49.4253Z"
        stroke="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 32.6187H14.775H9Z"
        stroke="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.8741 33.1002C37.8741 36.2899 35.285 38.8751 32.0992 38.8751C28.9133 38.8751 26.3242 36.2899 26.3242 33.1002C26.3242 29.9105 28.9133 27.3252 32.0992 27.3252C35.285 27.3252 37.8741 29.9105 37.8741 33.1002Z"
        stroke="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.6121 33.1003C44.6121 40.011 39.0104 45.6127 32.0996 45.6127C25.1889 45.6127 19.5872 40.011 19.5872 33.1003C19.5872 26.1896 25.1889 20.5879 32.0996 20.5879C39.0104 20.5879 44.6121 26.1896 44.6121 33.1003Z"
        stroke="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.3481 33.1C51.3481 43.7317 42.7338 52.3499 32.0982 52.3499C21.4627 52.3499 12.8483 43.7317 12.8483 33.1C12.8483 22.4683 21.4627 13.8501 32.0982 13.8501C42.7338 13.8501 51.3481 22.4683 51.3481 33.1Z"
        stroke="black"
      />
    </svg>
  );
}
