import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssignItemType } from '../../components/AssignCategory/AssignProducts.tsx/AssignProducts';
import commonConstants from '../../constants/common.constant';

export const PROPERTY_KEY = {
  PAGE: 'page',
  KEYWORD: 'keyword',
};

type AssignCategoryState = {
  page: number;
  limit: number;
  keyword: string;
  interest: AssignItemType | null;
  category: AssignItemType | null;
  sub_category: AssignItemType | null;
};

const initialState: AssignCategoryState = {
  page: commonConstants.ASSIGN_CATEGORY_DEFAULT_QUERY_PARAMS.page,
  limit: commonConstants.ASSIGN_CATEGORY_DEFAULT_QUERY_PARAMS.limit,
  keyword: commonConstants.ASSIGN_CATEGORY_DEFAULT_QUERY_PARAMS.keyword,
  interest: commonConstants.ASSIGN_CATEGORY_DEFAULT_QUERY_PARAMS.interest,
  category: commonConstants.ASSIGN_CATEGORY_DEFAULT_QUERY_PARAMS.category,
  sub_category:
    commonConstants.ASSIGN_CATEGORY_DEFAULT_QUERY_PARAMS.subCategory,
};

type PayloadProperty = {
  type: string;
  value: string | number | Date[] | any[] | any;
};
type PayloadProperties = PayloadProperty[];

const AssignCategorySlice = createSlice({
  name: 'assignCategory',
  initialState,
  reducers: {
    setProperty: (state, action: PayloadAction<PayloadProperty>) => {
      // @ts-ignore
      state[action.payload.type] = action.payload.value;
    },
    setProperties: (state, action: PayloadAction<PayloadProperties>) => {
      if (action.payload.length > 0) {
        action.payload.forEach((item: PayloadProperty) => {
          if (state.hasOwnProperty(item.type)) {
            // @ts-ignore
            state[item.type] = item.value;
          }
        });
      }
    },
  },
});

export const { setProperty, setProperties } = AssignCategorySlice.actions;

export default AssignCategorySlice.reducer;
