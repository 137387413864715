import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
};

export function CashIcon({ width = 64, height = 64 }: Props): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
    >
      <g clipPath="url(#clip0_1738_13073)">
        <path
          d="M52.5781 31.39L58.649 34.8596L22.2163 50.6471L4 39.7173L10.0721 37.3662M35.5762 38.7858L22.2163 44.575L4 33.6452L10.0721 31.294M52.5829 25.3203L58.649 28.7875L45.2903 34.8596M35.575 32.4307L22.2163 38.5028L4 27.5731L41.6471 13L58.649 22.7154L45.2903 28.7875M35.575 32.4307L45.2903 28.7875M35.575 32.4307L18.5731 21.5009M35.575 32.4307V44.575M45.2903 28.7875L28.2884 17.8577M45.2903 28.7875V40.9317"
          stroke="black"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1738_13073">
          <rect
            width="55.7177"
            height="38.4"
            fill="white"
            transform="translate(4 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
