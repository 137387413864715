import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
};

export function ChainIcon({ width = 39, height = 39 }: Props): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 39 39"
      fill="none"
    >
      <path
        d="M20.5691 12.5573C21.9965 12.9717 23.3503 13.7361 24.4738 14.8596C28.01 18.3958 28.01 24.133 24.4738 27.6785L16.4619 35.6811C12.9257 39.2174 7.18846 39.2174 3.6522 35.6811C0.115935 32.1449 0.115935 26.4077 3.6522 22.8714L11.6548 14.8596C12.2074 14.307 12.8152 13.8466 13.4598 13.4598M16.8119 16.9132C18.3498 16.4803 20.0627 16.8579 21.269 18.0643C23.0372 19.8324 23.0372 22.7056 21.269 24.4738L13.2572 32.4764C11.4983 34.2445 8.61585 34.2445 6.85693 32.4764C5.0888 30.7175 5.0888 27.8351 6.85693 26.0761L12.456 20.4678M18.7642 26.776C17.3368 26.3616 15.9923 25.5973 14.8596 24.4738C11.3233 20.9375 11.3233 15.2003 14.8596 11.6548L22.8714 3.6522C26.4077 0.115935 32.1449 0.115935 35.6811 3.6522C39.2174 7.18846 39.2174 12.9257 35.6811 16.4619L27.6785 24.4738C27.126 25.0263 26.5182 25.4868 25.8735 25.8735M22.5215 22.4202C20.9836 22.853 19.2707 22.4754 18.0643 21.269C16.2962 19.5009 16.2962 16.6277 18.0643 14.8596L26.0761 6.85693C27.8351 5.0888 30.7175 5.0888 32.4764 6.85693C34.2445 8.61585 34.2445 11.4983 32.4764 13.2572L26.8773 18.8655"
        stroke="black"
      />
    </svg>
  );
}
