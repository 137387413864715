import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
};

export function EyeIcon({ width = 64, height = 64 }: Props): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.0948 32.6748C38.0948 36.0394 35.3641 38.7701 31.9995 38.7701C28.635 38.7701 25.9043 36.0394 25.9043 32.6748C25.9043 29.3103 28.635 26.5796 31.9995 26.5796C35.3641 26.5796 38.0948 29.3103 38.0948 32.6748Z"
        stroke="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.1915 32.6749C44.1915 39.404 38.7302 44.8653 32.001 44.8653C25.2597 44.8653 19.8105 39.404 19.8105 32.6749C19.8105 25.9457 25.2597 20.4844 32.001 20.4844C38.7302 20.4844 44.1915 25.9457 44.1915 32.6749Z"
        stroke="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.40039 32.6747C6.40039 32.6747 29.2575 63.0046 57.6004 32.6747C29.2575 2.35702 6.40039 32.6747 6.40039 32.6747Z"
        stroke="black"
      />
    </svg>
  );
}
