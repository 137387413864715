import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
};

export function ClassicMouseIcon({
  width = 64,
  height = 64,
}: Props): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.8905 57.6003H29.236C22.8127 57.6003 17.5996 52.3988 17.5996 45.9639V25.0184C17.5996 21.1668 20.7298 18.0366 24.5815 18.0366H38.5451C42.3967 18.0366 45.5269 21.1668 45.5269 25.0184V45.9639C45.5269 52.3988 40.3138 57.6003 33.8905 57.6003Z"
        stroke="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5625 21.5273V30.8364V21.5273Z"
        stroke="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5996 34.3271H45.5269H17.5996Z"
        stroke="black"
      />
      <path
        d="M31.5625 18.0363V15.709C31.5625 10.5657 35.7283 6.3999 40.8716 6.3999"
        stroke="black"
      />
    </svg>
  );
}
