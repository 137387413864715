import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
};

export function ShoppingCartIcon({
  width = 64,
  height = 64,
}: Props): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5488 52.4156C18.5488 54.4987 20.2429 56.1803 22.3135 56.1803C24.3967 56.1803 26.0782 54.4987 26.0782 52.4156C26.0782 50.3324 24.3967 48.6509 22.3135 48.6509C20.2429 48.6509 18.5488 50.3324 18.5488 52.4156ZM43.6469 52.4156C43.6469 54.4987 45.341 56.1803 47.4116 56.1803C49.4947 56.1803 51.1763 54.4987 51.1763 52.4156C51.1763 50.3324 49.4947 48.6509 47.4116 48.6509C45.341 48.6509 43.6469 50.3324 43.6469 52.4156Z"
        stroke="black"
      />
      <path
        d="M57.451 18.7843L52.4313 40.1176H17.2941M17.2941 40.1176L11.0196 12.5098H5.99997M17.2941 40.1176L18.549 46.3921H51.1764"
        stroke="black"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.4499 18.7843H43.646H57.4499ZM43.646 18.7843C43.646 23.6408 39.7181 27.5686 34.8617 27.5686C30.0178 27.5686 26.0774 23.6408 26.0774 18.7843C26.0774 13.9278 30.0178 10 34.8617 10C39.7181 10 43.646 13.9278 43.646 18.7843ZM26.0774 18.7843H12.2734H26.0774Z"
        stroke="black"
      />
      <path
        d="M33.6075 21.294L38.6271 16.2744M31.0977 18.7842L33.6075 21.294L31.0977 18.7842Z"
        stroke="black"
        strokeLinecap="round"
      />
    </svg>
  );
}
